import { Post } from "@/common/models";
import { formatTimestamp } from "@/common/utils";
import Alert from "@/components/Alert";
import PostGridItem from "@/components/post/PostGridItem";
import { getLatestPosts } from "@/lib/PostAPI";
import { GetServerSideProps } from "next";
import Image from "next/image";
import Link from "next/link";

export default function LocalizedHome({
  posts,
  locale
}: {
  posts: Post[];
  locale: string;
}) {
  const content = () => {
    if (posts.length === 0) {
      return <Alert message="No posts found" />;
    }

    let heading = <></>;
    let offset = posts.length > 3 ? 3 : 1;
    if (posts.length > 3) {
      heading = (
        <div className="row g-1">
          <div className="col-lg-8 h-100">
            <Link
              href={`/posts/${posts[0].slug}`}
              className="text-decoration-none"
              locale={locale}
            >
              <div className="ratio ratio-16x9 w-100">
                <Image
                  src={posts[0].cover ?? "/images/placeholder.jpeg"}
                  alt="Cover"
                  fill
                  sizes="80vw"
                  priority
                  style={{
                    objectFit: "cover"
                  }}
                />
                <div className="position-absolute start-0 bottom-0 top-0 end-0 bg-dark bg-opacity-25"></div>
                <div className="position-relative">
                  <div className="position-absolute start-0 bottom-0 end-0 m-3">
                    <div className="d-flex flex-column align-items-start text-truncate">
                      <div className="small fw-medium px-2 py-1 text-light border border-white mb-2">
                        {posts[0].type}
                      </div>
                      <h5 className="text-white">{posts[0].title}</h5>
                      <div className="small text-light">
                        {formatTimestamp(posts[0].created_at ?? 0)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-4">
            <div className="row gx-1 h-100">
              <div className="col-12 col-md-6 col-lg-12 flex-grow-1 mb-1 mb-md-0 mb-lg-1">
                <Link
                  href={`/posts/${posts[1].slug}`}
                  className="h-100 text-decoration-none"
                  locale={locale}
                >
                  <div className="w-100 medium-down-ratio position-relative">
                    <Image
                      src={posts[1].cover ?? "/images/placeholder.jpeg"}
                      alt="Cover"
                      fill
                      sizes="60vw"
                      priority
                      style={{
                        objectFit: "cover"
                      }}
                    />

                    <div className="position-absolute start-0 top-0 end-0 bottom-0 bg-dark bg-opacity-25"></div>
                    <div className="position-absolute start-0 bottom-0 end-0 m-3">
                      <div className="d-flex flex-column align-items-start text-truncate">
                        <div className="small fw-medium px-2 py-1 text-light border border-white mb-2">
                          {posts[1].type}
                        </div>
                        <h5 className="text-white ">{posts[1].title}</h5>
                        <div className="small text-light">
                          {formatTimestamp(posts[1].created_at ?? 0)}
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-12 col-md-6 col-lg-12 flex-grow-1">
                <Link
                  href={`/posts/${posts[2].slug}`}
                  className="h-100 text-decoration-none"
                  locale={locale}
                >
                  <div className="w-100 medium-down-ratio position-relative">
                    <Image
                      src={posts[2].cover ?? "/images/placeholder.jpeg"}
                      alt="Cover"
                      fill
                      sizes="60vw"
                      priority
                      style={{
                        objectFit: "cover"
                      }}
                    />
                    <div className="position-absolute start-0 top-0 end-0 bottom-0 bg-dark bg-opacity-25"></div>
                    <div className="position-absolute start-0 bottom-0 end-0 m-3">
                      <div className="d-flex flex-column align-items-start text-truncate">
                        <div className="small fw-medium px-2 py-1 text-light border border-white mb-2">
                          {posts[2].type}
                        </div>
                        <h5 className="text-white ">{posts[2].title}</h5>
                        <div className="small text-light">
                          {formatTimestamp(posts[2].created_at ?? 0)}
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      heading = (
        <div className="row">
          <div className="col-12">
            <Link
              href={`/posts/${posts[0].slug}`}
              className="text-decoration-none"
              locale={locale}
            >
              <div className="ratio ratio-16x9 w-100">
                <Image
                  src={posts[0].cover ?? "/images/placeholder.jpeg"}
                  alt="Cover"
                  fill
                  sizes="80vw"
                  priority
                  style={{
                    objectFit: "cover"
                  }}
                />
                <div className="position-absolute start-0 top-0 end-0 bottom-0 bg-dark bg-opacity-25"></div>
                <div className="position-relative">
                  <div className="position-absolute start-0 bottom-0 end-0 m-3">
                    <div className="d-flex flex-column align-items-start text-truncate">
                      <div className="small fw-medium px-2 py-1 text-light border border-white mb-2">
                        {posts[0].type}
                      </div>
                      <h5 className="text-white ">{posts[0].title}</h5>
                      <div className="small text-light">
                        {formatTimestamp(posts[0].created_at ?? 0)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      );
    }

    return (
      <>
        {heading}
        <h3 className="mt-5 mb-4">Recent posts</h3>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
          {posts.slice(offset).map((p, i) => {
            return (
              <div key={p.id} className="col">
                <PostGridItem value={p} />
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <>
      <div className="container py-3 mb-5">{content()}</div>
    </>
  );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const locale = context.locale;

  try {
    if (locale) {
      const posts = await getLatestPosts(locale === "en" ? "EN" : "MM");

      return {
        props: {
          posts: posts,
          locale: locale
        }
      };
    } else {
      return {
        redirect: {
          statusCode: 302,
          destination: "/"
        }
      };
    }
  } catch (error) {}
  return {
    notFound: true
  };
};
