/* eslint-disable @next/next/no-img-element */
import { Post } from "@/common/models";
import { formatTimestamp } from "@/common/utils";
import { PlayCircleIcon, SpeakerWaveIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import { useRouter } from "next/router";

export default function PostGridItem({ value }: { value: Post }) {
  const router = useRouter();

  const locale = router.locale;

  const overlay = () => {
    if (value.type === "VIDEO") {
      return (
        <>
          <div className="position-absolute start-0 top-0 end-0 bottom-0 bg-dark bg-opacity-10"></div>
          <PlayCircleIcon
            width={64}
            className="position-absolute top-50 start-50 translate-middle text-light"
          />
        </>
      );
    }

    if (value.type === "PODCAST") {
      return (
        <>
          <div className="position-absolute start-0 top-0 end-0 bottom-0 bg-dark bg-opacity-10"></div>
          <SpeakerWaveIcon
            width={64}
            className="position-absolute top-50 start-50 translate-middle text-light"
          />
        </>
      );
    }

    return <></>;
  };

  return (
    <Link
      href={`/posts/${value.slug}`}
      className="d-flex flex-column text-decoration-none"
      locale={locale}
    >
      <div className="mb-2 position-relative">
        <img
          src={value.cover ?? "/images/placeholder.jpeg"}
          alt="Cover"
          width={"100%"}
          height="auto"
          style={{
            objectFit: "cover"
          }}
        />
        {overlay()}
        <img
          src={"/logo192.png"}
          alt="Logo"
          width={40}
          height={40}
          className="position-absolute start-0 top-0 m-2 opacity-75"
        />
      </div>
      <h6 className="mb-2h fw-semibold text-dark">{value.title}</h6>
      <div className="small text-muted">
        {formatTimestamp(value.created_at ?? 0)}
      </div>
    </Link>
  );
}
